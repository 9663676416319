.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*LOGIN ,"src/web.config"*/
.loginBackground {
	background-image: url(../../../../../assets/media/icons/ImagenLogin.jpg);
	background-size: cover;
	height: 100%;
}

.wrapper {
	display: flex;
	align-items: end;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	padding: 1rem;
}

.contendorLogin {
	width: 100%;
	height: 100%;
	padding: 20px;
}

.formContenedorLogin {
	border-radius: 30px 30px 30px 30px;
	padding: 10px 20px 20px 20px;
	width: 90%;
	max-width: 400px;
	background: rgb(255 255 255 / 90%);
	position: relative;
	padding-bottom: 30px;
	box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: #ec0000;
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: #ec0000;
}

.matformfieldLogin {
	width: 100%;
}

.labelLoginTitulo {
	text-align: center;
	width: 100%;
    color: #ec0000;
}


.divTextTitulo {
/* 	border-bottom: 2px solid; */
	margin: 5% 0% 6% 0%;
}

.botonIngresar {
	width: 100%;
}


.textLabelLoginSubTitulo{
    color: black;
    margin: 10%;
}
/*LOGIN FIN*/
input[type="password"] {
    letter-spacing: 0;
}

.textVersion{
    color: black;
    text-align: center;
	font-size: 14px;
	font-weight: bold;
}
