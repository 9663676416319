.request-dialong-content {
 padding: 20px;
 width: 1200px;
}

.request-dialog-title {
 text-align: center;
 font-size: 14px;
 font-weight: 700 !important;
 margin-bottom: 20px;
}

.request-dialog-btn-close {
 text-align: center;
 width: 238px;
 padding-top: 4px;
 padding-bottom: 4px;
 background-color: #444444;
 color: #FFF;
 border-radius: 100px;
 margin: auto;
}

.request-dialog-list-content {
 margin-bottom: 30px;
}