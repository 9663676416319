.cardDocumentation {
    margin-left: 5%;
}

.cardTabs {
    border-radius: 10px;
    margin-right: 2%;
    background-color: white;
}

.header-tab-documentation {
    color: #444444 !important;
    font-style: normal !important;
    font-weight: 750 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-transform: uppercase !important;
}

.cell-content-center {
    text-align: center !important;
}

.documentation-note {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
    background-color: #DFEEF2;
}

.documentation-mensaje-rechazo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
    background-color: #e9d6d6;
}

.documentation-note-bg strong {
    color: #444444;
    font-weight: bold;
}

.comments-chat {
    padding: 20px;
    background-color: #FFF;
    border-radius: 10px;
    height: 450px;
}

.comments-chat .comments-chat-title {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 20px;
}

.comments-messages {
    height: 275px;
    overflow: auto;
    margin-bottom: 20px;
    padding: 10px;
}

.comments-messages::-webkit-scrollbar {
    width: 10px;
}

.comments-messages::-webkit-scrollbar-thumb {
    background: #9F9F9F;
    border-radius: 10px;
}

.comments-messages::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.comments-messages::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.message-request {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.chat-message {
    border-radius: 20px;
    background-color: #F5F5F5;
    display: flex;
    padding: 20px;
    width: fit-content;
    max-width: 80%;
}

.my-message {
    align-items: start;
}

.last-message {
    background-color: #0089B5 !important;
    color: #FFF;
}

.chat-message-time {
    color: #A3A3A3;
}

.input-message-chat {
    position: relative;
}

.input-message-chat {
    border-color: #D7D7D7;
    border-width: 1px;
    width: 100%;
    padding: 10px;
    border-style: solid;
    border-radius: 10px;
}

.input-message-chat textarea {
    width: 80%;
    resize: none;
    border: none;
}

.input-message-chat textarea:focus {
    outline: none;
}

.btn-message-chat {
    position: absolute !important;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

// .documentation-button-steps {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     margin-right: 2%;
//     margin-bottom: 40px;
// }

.documentation-button-steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 2%;
    margin-bottom: 40px;
}

.documentation-btn-back {
    background-color: #444444;
    color: white;
    padding: 10px 0;
    border-radius: 20px;
    width: 250px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.documentation-btn-back img {
    position: absolute;
    top: 15px;
    left: 20px;
}

.documentation-btn-send {
    background-color: #EC0000;
    color: white;
    padding: 10px 0;
    border-radius: 20px;
    width: 250px;
    text-align: center;
    cursor: pointer;
    position: relative;
}


.documentation-btn-generic {
    background-color: #EC0000;
    color: white;
    padding: 10px 0;
    border-radius: 20px;
    width: 250px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin: 0 auto;
}

.documentation-btn-generic img {
    position: absolute;
    top: 15px;
    right: 20px;
}

.documentation-btn-send img {
    position: absolute;
    top: 15px;
    right: 20px;
}

.btn-red-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #9FA6AD;
    background-color: #FCE4E4;
}

.btn-grey-disabled {
    pointer-events: none;
    cursor: not-allowed;
    --Icon-color: currentColor;
    color: #9FA6AD;
    background-color: #EAEEF6;
}

.cursor-pointer {
    cursor: pointer;
}

.color-file-link {
    color: #0089B5;
}

.color-aproved {
    color: #00AF12;
    font-weight: bold;
}
.color-denided {
    color: #af0000;
    font-weight: bold;
}

.content-documentation-add-dialog {
    padding: 20px;
    width: 350px;

    .content-documentation-add-dialog-title {

        padding: 10px;

        h2 {
            text-align: center;
            font-size: 14px;
            font-weight: 700 !important;
        }

    }

    .content-documentation-add-dialog-footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 20px;
    }

    .content-documentation-add-dialog-footer-close {
        background-color: #444444;
        padding-left: 20px;
        padding-top: 8px;
        padding-bottom: 10px;
        padding-right: 30px;
        color: #FFF;
        border-radius: 20px;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
            margin-right: 10px;
        }
    }



}

.content-documentation-add-dialog-footer-add {
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 35px;
    background-color: #EC0000;

    color: #FFF;
    border-radius: 20px;

    display: flex;
    gap: 20px;

    img {
        margin: 0;
    }
}

.close-dialog-documentation {
    display: flex;
    justify-content: end;

    img {
        background-color: #EC0000;
        border-radius: 10px;
    }
}

.content-documentation-add-dialog-dni {
    padding: 10px 10px;
    width: 350px;
}

.content-documentation-add-dialog-dni-title {
    padding: 10px;

    h2 {
        text-align: center;
        font-size: 14px;
        font-weight: 700 !important;
    }
}

.content-documentation-add-dialog-dni-body {
    text-align: center;
}

.content-documentation-add-dialog-dni-body {
    img {
        margin-bottom: 10px;
    }

    p {
        font-size: 12px;
        padding: 0 20px;
    }
}

.content-documentation-add-dialog-dni-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.content-documentation-add-dialog-dni-footer-update-document {
    background-color: #EC0000;
    display: flex;
    color: #FFF;
    border-radius: 20px;
    padding: 10px 30px;
    gap: 10px;
}

.filter-verification-table {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: end;

    span {
        font-size: 13px;
    }
}

.btn-filter-verification-table {
    background-color: #EC0000;
    color: #FFF;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    height: 40px;
}

.content-documentation-add-dialog-body {
    min-height: 250px;
    max-height: 450px;
    overflow: auto;
}

.content-documentation-add-description-body {
    min-height: 120px;
    max-height: 120px;
    overflow: auto;
    display: flex;
    align-items: center;
}

.content-documentation-add-dialog-body::-webkit-scrollbar {
    width: 10px;
}

.content-documentation-add-dialog-body::-webkit-scrollbar-thumb {
    background: #9F9F9F;
    border-radius: 10px;
}

.content-documentation-add-dialog-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.content-documentation-add-dialog-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.documentation-message-alet {
    background-color: #FFFEE5;
    border-radius: 5px;
    padding: 10px 0;
    text-align: center;
    position: relative;
    font-size: 14px;
    margin-right: 2%;
    margin-bottom: 10px;
}

.documentation-message-icon {
    position: absolute;
    left: 15px;
    top: 15px;
}

.documentation-message-icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.filter-placa-table {
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    gap: 10px;
}

.validation-placa-check {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #0089B5;
    font-weight: bold;
}

.documentation-btn-action {
    background-color: #FFF;
    color: #EC0000;
    padding: 10px 0;
    border-radius: 20px;
    width: 250px;
    text-align: center;
    cursor: pointer;
    position: relative;
    border-color: #EC0000;
    border-style: solid;
    border-width: 0.5px;
}

.documentation-button-actions-section {
    display: flex;
    gap: 20px;
}

.grayscale {
    filter: grayscale(100%);
}

.request-exception-dialog-content {
    padding: 25px;
    max-width: 600px;
    max-height: 600px;
    border-radius: 100px;
}


.request-exception-dialog-title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 20px;
}