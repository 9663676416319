.validar-request-dialong-content {
 padding: 20px;
 width: 350px;
}

.validar-request-dialog-title {
 text-align: center;
 font-size: 14px;
 font-weight: 700 !important;
 margin-bottom: 20px;
}

.validar-request-dialog-btn-cancelar {
 display: flex;
 flex-direction: column;
 text-align: center;
 width: 124px;
 height: 40px;
 padding-top: 4px;
 padding-bottom: 4px;
 background-color: #444444;
 color: #FFF;
 border-radius: 100px;
 margin: auto;
}

.validar-request-dialog-btn-empezar {
 display: flex;
 flex-direction: column;
 text-align: center;
 width: 124px;
 height: 40px;
 //flex-shrink: 0;
 padding-top: 4px;
 padding-bottom: 4px;
 background-color: #EC0000;
 color: #FFF;
 border-radius: 100px;
 margin: auto;
}

.validar-request-dialog-list-content {
 margin-bottom: 30px;
 text-align: center;
}