.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 600px;
    background-color: white !important
}
