.css-19sovsq-JoySelect-button{
    overflow: hidden !important;
}
.cardBtnNewRequest{
    padding-right: 6%;
    padding-top: 0.5rem !important;

}
.btnNewRequest{
    background: white !important;
    border: 2px black solid;
}
.cardContentTrayOfRequest{
    height: auto;
}

.Tablethead{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
}
.tableContainer{
    border-radius: 20px;
    height: 36rem;
    padding: 0 10px 10px 10px;
    overflow-x: hidden;
    overflow-y: scroll;   
}
.tableContainer::-webkit-scrollbar {
    -webkit-appearance: none;
}

.tableContainer::-webkit-scrollbar:vertical {
    width:15px;
}

.tableContainer::-webkit-scrollbar-button:increment,.tableContainer::-webkit-scrollbar-button {
    display: none;
} 

.tableContainer::-webkit-scrollbar:horizontal {
    height: 10px;
}

.tableContainer::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.tableContainer::-webkit-scrollbar-track {
    border-radius: 10px;  
}
.headTable{
    background-color: #E1E1E1 !important;
}
.headTableColor{
    color: black !important;
}
.dropdown{
    position: unset !important;
}
.colorHref{
    color: blue;
    cursor: pointer;
}

.css-1bn53lx{
    border-radius: 54px !important;
}

.css-1uvydh2{
    padding: 10px 0px 10px 14px !important;
}

.css-a9onbf{
    border-radius:24px !important;
}

.cardObjets-bandeja{
    padding-top: 1.5rem !important;
    width: 15.2% !important;
}

.btnBuscar{
    padding-top: 1.5rem !important;
}

.excepcion-style {
    font-size: 8px;
    background-color: #03a5fc;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    color: #FFF;
}