.cardTeamManagementDialog {
    margin-left: 5%;
}

.marginTopDialog {
    margin-top: 10px;
}

.text-center {
    text-align: center;
    font-size: 20px;
    font-weight: 700 !important;
    margin-bottom: 3px;
    margin-top: 20px;
}