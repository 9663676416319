
.body-card {
    margin-left: 5%;
}

.body-card-col {
    background-color: #ffffff;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.body-options {
    background-color: #EBEBEB;
    border: 0px;
}

.cardObjets{
    padding-top: 1.5rem;
}

.buttonObjets{
    padding-top: 1.5rem;
    border-radius: 20px;
}
.textAlignLeft{
    text-align: left;
} 
.textAlignRigth{
    text-align: end;
} 

.textAlignCenter{
    text-align: center;
} 

.dialog-text-AlignCenter{
    text-align: center;
}

.left-button{
    text-align: left;
}
.center-button{
    justify-content: center;
    text-align: center;
} 
.right-button{
    text-align: end;
}

.table-thead {
    text-transform: uppercase;
    background: #E1E1E1;
    border-radius: 10px;
}
.table-thead-th {
    background:#E1E1E1;
}
.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background-color: #EC0000;
    height: 4px;
  }

.style-table {
    tr:nth-child(odd) {
      background-color: #F6F6F6;
      ;
    }
}
.card{
    margin-left: 5%;
    border-radius: 10px;
    margin-right: 2%;
}


.cardSmall{
    border-radius: 10px;
    height: 408px;
}

.horizontal-scrollable {
    overflow-x: auto;
    white-space: nowrap;
    max-height: 264px; 
  
    &__wrapper {
      display: inline-flex;
    }
}
.horizontal-generico-scrollable {
    overflow-x: auto;
    white-space: nowrap;
  
    &__wrapper {
      display: inline-flex;
    }
}

.vertical-scrollable {
    overflow-y: auto;
    white-space: normal;
    max-height: 264px; 
  
    &__wrapper {
      display: inline-flex;
    }
  }

.horizontal-vertical-scrollable {
    overflow-x: auto;
    overflow-y: auto;
    white-space: normal;
    max-height: 264px; 
  
    &__wrapper {
      display: inline-flex;
    }
  }


.body-card-title-start {
    padding-top: 17px;
}

/*
.body-quatition {
    caret-color: transparent;
}
  */

.cardTextTopQuatition{
    font-size: 30px;
    outline: none;
    caret-color: transparent;
}

.circle-btn-denegado {

    float: right;   
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: inherit;
    color: transparent;
    
}


.circle-btn-tooltip {

    float: right !important;   
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: inherit;
    color: transparent;
    transition: all 0.3s ease;
}

.circle-btn {

    float: right;   
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: inherit;
    color: transparent;
    transition: all 0.3s ease;
    
    &:hover {
        height: 16px;
        width: 36px;
        left: 0px;
        top: 0px;
        border-radius: 100px;
        box-shadow: none;
        background-color: #00AF12;  
        color: #ffffff;  
    }

    &:hover:before {
        transform: scale(1);
        opacity: 1;
    }
}

.circle-btn:focus {
    outline: none;
  }

.circle-btn-texto {  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    text-align: right;
    color:inherit;    
}

.dialog-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    line-height: 0.5;
    
    caret-color: transparent;
}

.detail-dialog {

    left: 41.74%;
    right: 41.74%;
    top: 35.77%;
    bottom: 54.05%;
    background: #DFEEF2;
    border-radius: 10px;
}

.detail-subtitle-dialog{
    left: 43.06%;
    right: 42.78%;
    top: 37.39%;
    bottom: 59.55%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* Gris oscuro */
    color: #444444;
}

.detail-item1-dialog{
    left: 43.06%;
    right: 48.89%;
    top: 40.99%;
    bottom: 54.95%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* Gris oscuro */

    color: #444444;
}

.detail-item2-dialog{
    left: 51.11%;
    right: 43.12%;
    top: 40.99%;
    bottom: 54.95%;    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;    
    /* Gris oscuro */    
    color: #444444;
}

.dialog-style{
    & .MuiDialog-paper {
        max-width: 275px;
      }
}

.dialog-style-alert{
    & .MuiDialog-paper {
        width: 719px;
        border-radius: 10px;
        caret-color: transparent;
      }
}

.item-body-dialog{
    margin-left: 5px;
    line-height: 0.5;
    caret-color: transparent;
}


.close-button{
    position: absolute;
}

.custom-select{    
    border-radius: 24px !important;
    height: 38.22px;
    caret-color: transparent;

    & .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7;
      }
    
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7;
      }
    
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7;
      }
}

.custom-textarea{
    border-radius: 10px !important;
    height: auto;
    caret-color: transparent;
}

.custom-datepicker{    
    caret-color: transparent;
}

.buttons-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    align-items: center; 
    caret-color: transparent;
  
    .left-button {
      order: 1; 
      margin-bottom: 10px;
    }
  
    .right-button {
      order: 2; 
    }
    
    @media screen and (max-width: 993px) { 
        justify-content: center; 
        flex-direction: column; 
        align-items: center; 
    
        .left-button {
          order: 1; 
          margin-bottom: 10px; 
          text-align: center;
        }
    
        .right-button {
          order: 2; 
          text-align: center;
        }
      }  
  }  
  
 .text-danger-message {
    color: #EC0000;
    display: block;
    margin-top: 5px;
    margin-left: 0;
    text-align: start;
}

.text-long {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.icon-quotation-link {
color: #0089B5;
}