$Green: #00AF12;
$Rojo: #EC0000;
$Amarrillo: #dde01d;

.text-link {
 color: #0089B5;
 cursor: pointer;
}

.text-motivo {
 width: 195px;
 height: 34px;
 flex-shrink: 0;
}

.linea-vertical {
 width: 1px;
 height: 66px;
 background: #0089B5;
}

.btn-score {
 width: 141px;
 height: 28px;
 flex-shrink: 0;
 border-radius: 100px;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}

.btn-score-bueno {
 border: $Green;
 background: $Green;
}

.btn-score-malo {
 border: $Rojo;
 background: $Rojo;
}

.btn-score-regular {
 border: $Amarrillo;
 background: $Amarrillo;
}

.img-score-in {
 width: 41.597px;
 height: 41.026px;
 flex-shrink: 0;
 color: $Rojo;
}

.img-score-out {
 width: 50.696px;
 height: 50px;
 flex-shrink: 0;
 fill: #FFF;
 stroke-width: 2px;
 stroke: $Rojo;
}