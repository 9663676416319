.body-details {
    background-color: #DFEEF2;
    color: #444444;
    border-radius: 10px;
    caret-color: transparent;
}
.item-body-details {
    margin-left: 5px;
}

.text-negrita {
    font-weight: bold;
}