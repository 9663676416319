.headSesionStateSimbol{

    width: 40px;
    height: 40px;
    left: 0px;
    top: 1px;
    background: #DFEEF2;
    border-radius: 100px;
    text-align: center;
    padding-top: 1%;
}

.divContenedorSimbol{
    text-align: "-webkit-right"
}
.css-1sf9zu4-JoyButton-root{
    border: none !important;
}