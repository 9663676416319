@import './../../1.-shared/1.2.-layout/1.2.1.-custom-nav/custom-nav.scss';

* {
  margin: 0;
  padding: 0px;
}

.cardMatrizEvaluacion {
  margin-left: 5%;
  margin-right: 2%;
}

.cardMatrizEvaluacionModal {
  margin-left: 0;
  margin-right: 0;
}