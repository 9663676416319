.reassign-request-dialog-content {
  padding: 25px;
  max-width: 350px;
  max-height: 700px;
  border-radius: 100px;
  }

  .reassign-request-dialog-title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 20px;
  }
  
  .reassign-request-info-container {
    background-color: #DFEEF2;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 16px;
  }
  
  .reassign-request-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
  }
  
  .reassign-request-label-info {
    margin-right: 8px;
    font-size: 12px;
  }
  
  .reassign-request-label {
    font-size: 13px;
  }

  .reassign-request-input {
    margin-bottom: 16px;
  }
  
  .reassign-request-textarea {
    width: 100%;
    margin-bottom: 16px;
  }


  .reassign-request-dialog-btn-save {
    text-align: center;
  }

.boton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
  width: 238px;
  height: 40px;
}

.button-text {
  text-align: center;
  width: 100%;
}

.arrow-right {
  position: absolute;
  right: 10px;
}


.reassign-request-table-container {
  width: 750%;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #E1E1E1;
  border-radius: 100px;
  font-size: 11px;
}

.reassign-request-table-par {
  background-color: #FFFFFF;
  font-size: 12px;
}

.reassign-request-table-impar {
  background-color: #F6F6F6;
}

.header-cell {
  font-size: 11px;
}

.body-cell {
  font-size: 12px;
}

.container-table {
  width: 100%;
  overflow-x: auto;
}

.change-rate-close-btn {
  background-color: transparent;
  border: none;
  //color: #000;
  font-size: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  padding: 0;
  cursor: pointer;
}