.select-input-card {
    border-radius: 25px !important;
}

.select-input-card .MuiSelect-select {
    padding: 9px 20px;
}

.width-160px {
    width: 160px;
}

.MuiAutocomplete-inputRoot {
    padding: 1px 4px !important;
    border-radius: 25px !important;
}

.percentage-input input {
    text-align: center;
}

.custom-date-picker {
    border-radius: 24px !important;
    height: 38.22px;
    caret-color: transparent;
    // background-color: var(--joy-palette-background-surface, var(--joy-palette-common-white, white));

    & .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7 !important;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7 !important;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #D7D7D7 !important;
    }
}