.carousel-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: 92%;
    gap: 10px;
}

.slide {
    flex: 0 0 auto;
    min-width: 150px;
    padding: 10px;
    border: 1px solid #F6F6F6;
    transition: transform 0.5s ease;
    border-radius: 5%;
    background: #F6F6F6;
}

.slide.active {
    font-weight: bold;
    border-color: #48c0cb;
    background: #ecebeb;
}


.slide:hover {
    cursor: pointer;
    font-weight: bold;
    background: #ecebeb;
}

.prev-button,
.next-button {
    padding: 10px 20px;
    border: none;
    background-color: #eee;
    cursor: pointer;
    height: 80px;
    width: 40px;
}

.prev-button:hover,
.next-button:hover {
    background-color: #ddd;
}
.carousel::-webkit-scrollbar {
    height: 10px; 
  }
.carousel::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.carousel::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}