.request-ratio-dialog-content {
    padding: 20px;
    // width: 600px;
    min-width: 200px;
}

.request-ratio-dialog-title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 20px;
}

.request-ratio-dialog-btn-close {
    text-align: center;
    width: 238px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #444444;
    color: #FFF;
    border-radius: 100px;
    margin: auto;
}

.request-ratio-dialog-list-content {
    margin-bottom: 30px;
}