.comment-modal-content {
    padding: 25px;
    max-width: 600px;
    max-height: 600px;
    border-radius: 100px;
}

.comment-modal-close-btn {
    background-color: transparent;
    border: none;
    font-size: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 0;
    cursor: pointer;
}

.comment-modal-dialog-title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 20px;
}