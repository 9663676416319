.select-input-card {
    border-radius: 25px !important;
}
.select-input-card .MuiSelect-select {
    padding: 9px 20px;
}
.width-160px {
    width: 160px;
}
.MuiAutocomplete-inputRoot{
    padding: 1px 4px !important;
    border-radius: 25px !important;
}