@import './../../1.-shared/1.2.-layout/1.2.1.-custom-nav/custom-nav.scss';

* {
  margin: 0;
  padding: 0px;
}

.card {
  margin-left: 5%;
}

.cardTop {
  border-right: 2px solid #000000;
}

.cardTextTop {
  font-size: 30px;
  outline: none;
}

.textAlignRigth {
  text-align: end;
}

.textLabelHeadboard {
  padding-bottom: 1rem;
}


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.css-1lyqkne-JoyInput-root {
  border-radius: 24px !important;
}

.css-om8c7-JoySelect-root {
  border-radius: 24px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 24px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 24px !important;
}

.MuiSelect-root {
  border-radius: 24px !important;
}

.cardObjets {
  padding-top: 1.5rem;
}

.jqx-datetimeinput {
  width: 100% !important;
}

.content-solicitude {
  margin-left: 5%;
}

.bottom-right {
  text-align: end
}

.conteint-img {
  position: relative;
  display: inline-block;
  text-align: center;
}

.conteint-img-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.borderOtherIncome {
  border-top: 1px solid blue;
  margin-top: 5vh !important;
}

// Estilos del componente resultados

.solicitude-mt-20 {
  margin-top: 20px;
}

.solicitude-mb-20 {
  margin-bottom: 20px;
}

.solicitude-mb-60 {
  margin-bottom: 60px;
}


.solicitude-principal-detail {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}

.solicitude-principal-detail-title {
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  width: 20%;
}

.solicitude-principal-detail-content {
  width: 80%;
}

.solicitude-response-icon-left {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: start;
  border-left-style: solid;
  border-left-color: #0089B5;
  border-width: 1px;
  padding-left: 35px;
}

.solicitude-response-detail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.solicitude-row-evaluacion {
  display: flex;
  flex-direction: row;
}

.solicitude-button-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2%;
  margin-bottom: 20px;
}

.solicitude-buttons-right {
  float: right;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.solicitude-button-back {
  background-color: #444444;
  color: white;
  padding: 10px 0;
  border-radius: 20px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    left: 20px;
  }
}

.solicitude-button-red {
  background-color: #EC0000;
  color: white;
  padding: 10px 0;
  border-radius: 20px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    right: 20px;
  }

}

.solicitude-button-red-disable {
  @extend .solicitude-button-red;
  cursor: default;
  border: 0.5px solid #bdbdbd;
  background-color: #fbfcfe !important;
  color: #00000061 !important;
}

.solicitude-button-documentation {
  background-color: #FFF;
  color: #EC0000;
  padding: 10px 0;
  border-radius: 20px;
  font-weight: 700;
  border-style: solid;
  border-color: #EC0000;
  border-width: 1px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.solicitude-exception {
  background-color: #FFF;
  border-radius: 10px;
  padding: 0 40px;
}

.solicitude-exception-title {
  padding: 30px 0;
  font-weight: 700;
  font-size: 14px;
  color: #444444;
}

.solicitude-exception-status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
  padding-bottom: 20px;

  input {
    border-radius: 100px;
    padding: 15px 20px;
    background-color: #F5F5F5;
    border-style: solid;
    border-color: #D7D7D7;
    border-width: 1px;
    font-size: 15px;
    font-weight: 400;
  }
}

.solicitude-expcetion-comment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
  padding-bottom: 20px;

  textarea {
    background-color: #FFF;
    border-style: solid;
    border-color: #D7D7D7;
    border-width: 1px;
    border-radius: 10px;
    resize: none;
    height: 140px;
    padding: 15px;
  }

  textarea:focus {
    outline: none;
  }

}

.body-alert-warning {
  background-color: #fbf7b9;
  color: #444444;
  border-radius: 10px;
  caret-color: transparent;
}

.item-alert-warning {
  margin-left: 5px;
  text-align: center;
}

.text-negrita {
  font-weight: bold;
}

.text-long {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.icon-solicitude-link {
  color: #0089B5;
}

.solicitude-linear-progress {
  background-color: lightgray !important;
  color: #EC0000 !important;

  .MuiLinearProgress-bar {
    background-color: #EC0000 !important;
  }
}

.solicitude-card-table {
  padding-top: 1.5rem;
}