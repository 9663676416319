.tranking-list-content {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.tranking-list-circulos {
    display: flex;
    gap: 20px;
}

.tranking-list-container-circulo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tranking-list-container-circulo-forma {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tranking-list-container-content {
    width: max-content;
}

.tranking-list-content-endline {
    display: flex;
    width: 30px;
    justify-content: center;
}

.tranking-list-content-endline-color {
    border-left: 1px solid #CCC;
    min-height: 40px;
}

.tranking-list-container-content-title-disable {
    color: #939393;
    font-weight: lighter;
}