$color-item-member: #F6F6F6;
$color-item-select: #0089B5;


.item-member {
    border-radius: 10px;
    border: 1px solid;
    border-color: $color-item-member;
    background: #F6F6F6;
    width: auto;
    height: 73px;
    flex-shrink: 0;

    .item-select {
        border-color: $color-item-select;
    }
}

.item-member:hover {
    cursor: pointer;
    font-weight: bold;
    border-color: #48c0cb;
}

.item-member:active {
    border-color: #48c0cb;
    font-weight: bold;

}

.text-long {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}